import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FaChevronRight } from 'react-icons/fa';
import { RawPortableText } from '../../types';
import { clsx, getTOCBlocks, slugify } from '../../utils/utils';
import Accordion from './Accordion';

import * as styles from './TableOfContents.module.scss';

export interface TableOfContentsProps {
  className?: string;
  content: RawPortableText;
}

const TableOfContents = ({ className, content }: TableOfContentsProps): React.ReactElement => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const allBlocks = getTOCBlocks(content);

  return (
    <div className={clsx(className, styles.container)}>
      <Accordion
        isAccordionOpen={isAccordionOpen}
        onAccordionBoxTouch={() => setIsAccordionOpen(!isAccordionOpen)}
        className={styles.accordion}
        title={'Table of Contents'}
        accordionBoxClassName={styles.tableOfContentAccordionBox}
      >
        <div className={styles.divider}></div>
        {allBlocks.map(
          (block, i) =>
            block.title && (
              <AnchorLink
                href={'#' + slugify(block.title)}
                key={i}
                className={styles.titleAndSubtitleContainer}
                offset="200"
              >
                <div className={styles.accordionTitle}>
                  <FaChevronRight className={styles.accordionTitleIcon} />
                  {block.title}
                </div>
              </AnchorLink>
            ),
        )}
      </Accordion>
      <div className={styles.desktopContainer}>
        <div className={styles.tableTitleBox}>
          <span>Table of Contents</span>
          <div className={styles.desktopTitleDivider}></div>
        </div>
        <div className={clsx(styles.box, allBlocks.length >= 5 && styles.columnBox)}>
          {allBlocks.map(
            (block, i) =>
              block.title && (
                <AnchorLink
                  href={'#' + slugify(block.title)}
                  key={i}
                  className={styles.titleAndSubtitleContainer}
                >
                  <FaChevronRight className={styles.accordionTitleIcon} />
                  <div className={styles.title}>{block.title}</div>
                </AnchorLink>
              ),
          )}
        </div>
      </div>
    </div>
  );
};

export default TableOfContents;
