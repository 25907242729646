import React, { useEffect, useRef, useState } from 'react';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx, getTOCBlocks, withDataLayer } from '../../utils/utils';
import usePortal from 'react-useportal';
import FsLightbox from 'fslightbox-react';
import { FaPlay } from 'react-icons/fa';
import getYouTubeID from 'get-youtube-id';
import { FaStar } from 'react-icons/fa';
import HeroForm from './HeroForm';
import TableOfContents from './TableOfContents';
import { RawPortableText } from '../../types';

import * as styles from './Hero.module.scss';
import '../../styles/main.scss';
import { useDimensions, useWindowDimensions } from '../../utils/hooks';
import { BREAKPOINT_LAPTOP } from '../../constants';

const MIN_MARGIN_BETWEEN_FORM_AND_WINDOW_HEIGHT = 50;

export type HeroProps = {
  heroType: 'homepage' | 'detailPage' | 'genericPage';
  topic?: string;
  title: string;
  isTextBelowTitle?: boolean;
  text?: string;
  color?: 'green' | 'grapefruit' | 'blue';
  withForm?: boolean;
  hasResponsiblePerson?: boolean;
  personImage?: {
    asset: {
      url: string;
    };
    alt?: string;
  };
  personName?: string;
  videoUrl?: string;
  children?: React.ReactNode;
  underTitleChildren?: React.ReactNode;
  hasApprovedTag?: boolean;
  withTableOfContents?: boolean;
  tableOfContentsPortableText?: RawPortableText;
  mobileImage?: {
    asset: {
      url: string;
    };
    alt?: string;
  };
} & (
  | {
      images?: Array<{
        asset: {
          url: string;
        };
        alt?: string;
      }>;
      singleImage?: never;
    }
  | {
      images?: never;
      singleImage?: {
        asset: {
          url: string;
        };
        alt?: string;
      };
    }
);

const Hero = ({
  heroType,
  hasResponsiblePerson,
  personImage,
  personName,
  videoUrl,
  topic,
  title,
  isTextBelowTitle,
  text,
  images,
  singleImage,
  color,
  withForm,
  children,
  underTitleChildren,
  hasApprovedTag,
  withTableOfContents,
  tableOfContentsPortableText,
  mobileImage,
}: HeroProps): React.ReactElement => {
  const [lightboxToggler, setLightboxToggler] = useState(false);
  const [mainElNode, setMainElNode] = useState<HTMLElement | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [formStep, setFormStep] = useState<string | null>(null);

  const formDimensions = useDimensions(formRef, { measureOnScroll: false }, [formStep]);
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const { Portal } = usePortal({ bindTo: mainElNode || undefined });

  const id = videoUrl ? getYouTubeID(videoUrl) : '';
  const embedUrl = `https://www.youtube.com/embed/${id}`;

  const noImage = !(singleImage || (images && images.length > 0));

  const portableTextHasH2 =
    tableOfContentsPortableText &&
    getTOCBlocks(tableOfContentsPortableText).filter(block => block.title).length > 0;

  useEffect(() => {
    setMainElNode(document.getElementById('main'));
  }, []);

  return (
    <section
      className={clsx(
        styles.heroSection,
        ((heroType === 'homepage' || images?.length === 6) && styles.homepage) ||
          (heroType === 'detailPage' && styles.detailPage) ||
          (heroType === 'genericPage' && styles.genericPage),
        noImage && styles.plainHero,
        withForm && styles.withForm,
      )}
    >
      <div
        className={clsx(
          styles.container,
          hasResponsiblePerson && personImage ? styles.higherHero : '',
          withForm && styles.withForm,
        )}
      >
        {withForm &&
          (windowWidth && windowWidth >= BREAKPOINT_LAPTOP && mainElNode ? (
            <Portal>
              <HeroForm
                setFormRef={ref => (formRef.current = ref)}
                className={clsx(
                  styles.form,
                  styles.formInPortal,
                  formDimensions &&
                    windowHeight &&
                    formDimensions.height + MIN_MARGIN_BETWEEN_FORM_AND_WINDOW_HEIGHT <
                      windowHeight &&
                    styles.formSticky,
                )}
                onStepChange={setFormStep}
              />
            </Portal>
          ) : (
            <HeroForm
              setFormRef={ref => (formRef.current = ref)}
              className={clsx(styles.form)}
              onStepChange={setFormStep}
            />
          ))}
        {images?.length === 6 && (
          <div className={styles.imagesContainer}>
            {images.map((image, i) => (
              <div
                key={i}
                className={clsx(styles.imageContainer, mobileImage && styles.withMobileImage)}
              >
                <img
                  src={image.asset.url + '?w=840&h=840&fit=max'}
                  alt={image.alt || ''}
                  className={styles.image}
                />
              </div>
            ))}
          </div>
        )}
        {mobileImage && (
          <div className={styles.mobileImage}>
            <img src={mobileImage.asset.url} alt={mobileImage.alt || ''} className={styles.image} />
          </div>
        )}
        <div
          className={clsx(styles.titleContainer, mobileImage && styles.withMobileImage)}
          style={{
            backgroundColor:
              color &&
              {
                green: '#3cc393',
                blue: '#87ceeb',
                grapefruit: '#f86d3a',
              }[color],
          }}
        >
          <div
            className={clsx(styles.bar, mobileImage && styles.withMobileImage)}
            style={{
              backgroundColor:
                color &&
                {
                  green: '#3cc393',
                  blue: '#87ceeb',
                  grapefruit: '#f86d3a',
                }[color],
            }}
          >
            {(singleImage || (images && images.length > 0)) && (
              <div className={clsx(styles.imagesContainer, mobileImage && styles.withMobileImage)}>
                {images?.length === 1 &&
                  images.map((image, i) => (
                    <div
                      key={i}
                      className={clsx(styles.imageContainer, !mobileImage && styles.noMobileImage)}
                    >
                      <img
                        src={image.asset.url + '?w=840&h=840&fit=max'}
                        alt={image.alt || ''}
                        className={styles.image}
                      />
                      {videoUrl && (
                        <div
                          className={styles.videoPlayContainer}
                          onClick={() => {
                            setLightboxToggler(!lightboxToggler);
                            withDataLayer(dataLayer => {
                              dataLayer.push({
                                event: 'hero-video-clicked',
                              });
                            });
                          }}
                        >
                          <div className={clsx(styles.background)}></div>
                          <FaPlay className={clsx(styles.icon)} />
                          <span className={styles.text}>Watch presentation</span>
                          <Portal>
                            <FsLightbox
                              toggler={lightboxToggler}
                              sources={[
                                // @ts-ignore
                                <iframe
                                  key="video"
                                  className={styles.iframe}
                                  src={embedUrl + '?autoplay=1&enablejsapi=1'}
                                  enablejsapi="true"
                                ></iframe>,
                              ]}
                            ></FsLightbox>
                          </Portal>
                        </div>
                      )}
                    </div>
                  ))}

                {singleImage && (
                  <div
                    className={clsx(
                      styles.imageContainer,
                      videoUrl && styles.imageContainerWithVideoButton,
                    )}
                    onClick={() => {
                      setLightboxToggler(!lightboxToggler);
                      withDataLayer(dataLayer => {
                        dataLayer.push({
                          event: 'hero-video-clicked',
                        });
                      });
                    }}
                  >
                    <div className={styles.imageWrapper}>
                      <img
                        src={singleImage.asset.url + '?w=840&h=840&fit=max'}
                        alt={singleImage.alt || ''}
                        className={styles.image}
                      />
                    </div>
                    {hasResponsiblePerson && personImage && (
                      <div className={styles.personImage}>
                        <img
                          src={personImage.asset.url}
                          alt={personImage.alt || ''}
                          title={personName ? personName : ''}
                        />
                      </div>
                    )}

                    {videoUrl && (
                      <button className={styles.videoPlayContainer}>
                        <div className={clsx(styles.background)}>
                          <FaPlay className={clsx(styles.icon)} />
                          <span className={styles.text}>Watch presentation</span>
                        </div>

                        <Portal>
                          <FsLightbox
                            toggler={lightboxToggler}
                            sources={[
                              // @ts-ignore
                              <iframe
                                key="video"
                                className={styles.iframe}
                                src={embedUrl + '?autoplay=1&enablejsapi=1'}
                                enablejsapi="true"
                              ></iframe>,
                            ]}
                          ></FsLightbox>
                        </Portal>
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          {topic && (
            <div
              className={clsx(
                styles.topicContainer,
                hasResponsiblePerson && hasApprovedTag && styles.topicContainerWithApprovedTag,
                noImage && styles.noImage,
              )}
            >
              <span className={styles.topic}>{topic}</span>
              {hasApprovedTag && (
                <div className={styles.approvedBox}>
                  <FaStar className={styles.approvedIcon} />
                  <span className={styles.approved}>Approved</span>
                </div>
              )}
            </div>
          )}
          {topic && <div className={clsx(styles.topicLine, noImage && styles.noImage)}></div>}
          <h1
            className={clsx(
              styles.title,
              !topic && styles.noTopic,
              noImage && styles.noImage,
              noImage && !topic && styles.noImageAndNoTopic,
              title.length > 50 && styles.smallerTitle,
            )}
          >
            {title}
          </h1>
          {isTextBelowTitle && text && (
            <p className={clsx(styles.paragraph, styles.paragraphBelowText)}>
              {replaceNewLinesWithBr(text)}
            </p>
          )}
          {underTitleChildren}
        </div>

        {(text || children) && (
          <div className={styles.detailsContainer}>
            {!isTextBelowTitle && text && (
              <p className={styles.paragraph}>{replaceNewLinesWithBr(text)}</p>
            )}
            {children && children}
          </div>
        )}
        {withTableOfContents && portableTextHasH2 && (
          <div
            className={clsx(
              styles.tableOfContentsContainer,
              withForm && styles.withForm,
              withForm && !text && styles.largerMarginTop,
              title.length <= 40 && styles.withShortTitle,
            )}
          >
            <TableOfContents
              className={styles.articleTableOfContents}
              content={tableOfContentsPortableText}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default Hero;
