// extracted by mini-css-extract-plugin
export var accordion = "TableOfContents-module--accordion--dc456";
export var accordionTitle = "TableOfContents-module--accordionTitle--b0ba4";
export var accordionTitleIcon = "TableOfContents-module--accordionTitleIcon--1b5f3";
export var box = "TableOfContents-module--box--a8c43";
export var columnBox = "TableOfContents-module--columnBox--b017c";
export var container = "TableOfContents-module--container--8b9d0";
export var desktopContainer = "TableOfContents-module--desktopContainer--02d0d";
export var desktopTitleDivider = "TableOfContents-module--desktopTitleDivider--b8a58";
export var tableOfContentAccordionBox = "TableOfContents-module--tableOfContentAccordionBox--82bee";
export var tableTitleBox = "TableOfContents-module--tableTitleBox--a41bd";
export var title = "TableOfContents-module--title--e9d6e";
export var titleAndSubtitleContainer = "TableOfContents-module--titleAndSubtitleContainer--24ed7";