import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import * as styles from './404.module.scss';

import LayoutContainer from '../components/ui/LayoutContainer';
import Menu from '../components/ui/Menu';
import PageSEO from '../components/PageSEO';
import { SingleEntry, SubmenuEntry } from '../graphql-fragments/Header';
import Hero from '../components/ui/Hero';

import image404 from '../images/error404Image.jpg';

interface QueryData {
  sanityWebsite: {
    header: Array<SingleEntry | SubmenuEntry>;
  };
}

const Page404 = (): React.ReactElement => {
  const data: QueryData = useStaticQuery(
    graphql`
      {
        sanityWebsite {
          header {
            ...SingleEntry
            ...SubmenuEntry
          }
        }
      }
    `,
  );

  const menuEntries = data.sanityWebsite.header;

  return (
    <LayoutContainer>
      <PageSEO
        pageSEO={{
          title: 'Page not found',
          description: 'The address entered do not match any page on our website.',
        }}
      />
      <Hero
        heroType={'genericPage'}
        singleImage={{
          asset: {
            url: image404,
          },
        }}
        color="green"
        topic="error 404"
        title="page not found"
        text="Oops, are you lost?
        Because it seems that the address entered does not correspond to any page on our website.
        Maybe you can find what you are looking for in one of the sections of the website below."
      ></Hero>

      <div className={styles.menuWrapper}>
        <div className={styles.menuContainer}>
          <Menu entries={menuEntries} desktopMenuWithSubEntries></Menu>
        </div>
      </div>
    </LayoutContainer>
  );
};

export default Page404;
