// extracted by mini-css-extract-plugin
export var approved = "Hero-module--approved--3524c";
export var approvedBox = "Hero-module--approvedBox--05652";
export var approvedIcon = "Hero-module--approvedIcon--ddfe5";
export var articleTableOfContents = "Hero-module--articleTableOfContents--da98e";
export var background = "Hero-module--background--8b66d";
export var bar = "Hero-module--bar--41b54";
export var container = "Hero-module--container--61826";
export var detailPage = "Hero-module--detailPage--252e4";
export var detailsContainer = "Hero-module--detailsContainer--7515f";
export var form = "Hero-module--form--94a00";
export var formInPortal = "Hero-module--formInPortal--1eab3";
export var formSticky = "Hero-module--formSticky--afaae";
export var genericPage = "Hero-module--genericPage--92f96";
export var heroSection = "Hero-module--heroSection--aad7b";
export var higherHero = "Hero-module--higherHero--134a4";
export var homepage = "Hero-module--homepage--2f447";
export var icon = "Hero-module--icon--df763";
export var iframe = "Hero-module--iframe--32721";
export var image = "Hero-module--image--8cca1";
export var imageContainer = "Hero-module--imageContainer--46dc8";
export var imageContainerWithVideoButton = "Hero-module--imageContainerWithVideoButton--417f7";
export var imageWrapper = "Hero-module--imageWrapper--da4e7";
export var imagesContainer = "Hero-module--imagesContainer--01236";
export var largerMarginTop = "Hero-module--largerMarginTop--caf55";
export var mobileImage = "Hero-module--mobileImage--e7cc3";
export var noImage = "Hero-module--noImage--6d0b1";
export var noImageAndNoTopic = "Hero-module--noImageAndNoTopic--b0062";
export var noTopic = "Hero-module--noTopic--5a23f";
export var paragraph = "Hero-module--paragraph--e435a";
export var paragraphBelowText = "Hero-module--paragraphBelowText--61918";
export var personImage = "Hero-module--personImage--35df2";
export var plainHero = "Hero-module--plainHero--84816";
export var smallerTitle = "Hero-module--smallerTitle--f8315";
export var tableOfContentsContainer = "Hero-module--tableOfContentsContainer--d5fef";
export var text = "Hero-module--text--0752e";
export var title = "Hero-module--title--044fe";
export var titleContainer = "Hero-module--titleContainer--ab9e4";
export var topic = "Hero-module--topic--5cc87";
export var topicContainer = "Hero-module--topicContainer--0692e";
export var topicContainerWithApprovedTag = "Hero-module--topicContainerWithApprovedTag--13d04";
export var topicLine = "Hero-module--topicLine--f185b";
export var videoPlayContainer = "Hero-module--videoPlayContainer--d1255";
export var withForm = "Hero-module--withForm--882c6";
export var withMobileImage = "Hero-module--withMobileImage--fa4b1";
export var withShortTitle = "Hero-module--withShortTitle--508c0";